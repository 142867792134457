import React, { useContext } from "react";
import { Uploading } from "../context/UploadingContext";
import Progress from "./Progress";
import { useLocation } from "react-router-dom";

const GlobalUploader = () => {
	const { uploadProgress, filesUploading, currentFile } =
		useContext(Uploading);
	const location = useLocation();
	const pathname = location.pathname;
	if (filesUploading.length && pathname !== "/folders") {
		return (
			<div className="fixed bottom-0 z-50 left-0 w-full p-5 bg-gray">
				<p className="font-bold text-xl text-center mb-2">
					Uploading Now: {filesUploading[currentFile].name} | (
					{currentFile + 1}/{filesUploading.length})
				</p>
				<div className="relative">
					<Progress
						height={20}
						width={uploadProgress}
						background="#7e3dd9"
					/>
					<p className="center-absolute text-xl">{uploadProgress}%</p>
				</div>
			</div>
		);
	} else {
		return <></>;
	}
};

export default GlobalUploader;
