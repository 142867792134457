import React, { useRef } from "react";
import streamSaver from "streamsaver";

const VideoPreview = ({ data, setEmbedVideo, setSubtitle, setDeleteVideo }) => {
	const abortControllerRef = useRef(null); // To manage the fetch cancellation
	const writerRef = useRef(null); // To manage the writable stream

	const downloadVideo = async () => {
		const abortController = new AbortController();
		abortControllerRef.current = abortController;

		try {
			const response = await fetch(data.original_link, {
				signal: abortController.signal,
			});
			const reader = response.body.getReader();
			const contentLength = parseInt(
				response.headers.get("Content-Length"),
				10
			);

			const fileStream = streamSaver.createWriteStream(
				data.title.includes(".mp4") ? data.title : data.title + ".mp4",
				{
					size: contentLength, // Pass file size here
				}
			);
			const writer = fileStream.getWriter();
			writerRef.current = writer; // Store writer reference for cleanup

			// Monitor the writer for abort or cancellation
			writer.closed.catch((err) => {
				if (err && err.name === "AbortError") {
					console.log("Stream canceled by the user.");
				} else if (err) {
					console.error("Writable stream error:", err);
				}
				cleanup(); // Cleanup after stream error
			});

			let receivedLength = 0;

			const pump = async () => {
				try {
					const { done, value } = await reader.read();
					if (done) {
						await writer.close(); // Ensure writer is closed
						console.log("Download completed successfully.");
						return;
					}
					await writer.write(value);
					receivedLength += value.length;

					// You can optionally track progress here
					console.log(
						`Download progress: ${(
							(receivedLength / contentLength) *
							100
						).toFixed(2)}%`
					);

					pump();
				} catch (err) {
					console.error("Download failed:", err);
					cleanup(); // Cleanup on pump error
				}
			};

			pump();
		} catch (err) {
			if (err.name === "AbortError") {
				console.log("Fetch canceled by the user.");
			} else {
				console.error("Download failed:", err);
			}
			cleanup(); // Cleanup on error
		}
	};

	const cleanup = () => {
		// Cleanup references after cancellation or completion
		if (abortControllerRef.current) {
			abortControllerRef.current = null;
		}
		if (writerRef.current) {
			writerRef.current = null;
		}
	};

	return (
		<div className="flex justify-between flex-col gap-5">
			<div className="flex">
				<div className="button-lg w-full">
					<iframe
						title={data.title}
						src={
							data.iframe_url
								.split("video.wolvy.stream")
								.join("iframe.mediadelivery.net/embed") +
							"?autoplay=false&responsive=true"
						}
						className="h-72 w-full object-contain"
						allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
						allowFullScreen="true"
					/>
				</div>
			</div>
			<div
				className="button-lg justify-center w-full p-4 flex gap-5 items-center"
				onClick={() => setEmbedVideo(true)}
			>
				<img
					src="/images/code.svg"
					className="h-5 invert brightness-[2%]"
					alt="Code"
				/>
				<p className="font-bold">EMBED VIDEO</p>
			</div>
			{data.original_link && (
				<button
					onClick={downloadVideo}
					className="button-lg !bg-yellow justify-center w-full p-4 flex gap-5 items-center"
				>
					<img
						src="/images/code.svg"
						className="h-5 brightness-0 saturate-100"
						alt="Code"
					/>
					<p className="font-bold text-black">
						Download Original Video
					</p>
				</button>
			)}
			<div
				className="button-lg !bg-lavander justify-center w-full p-4 flex gap-5 items-center"
				onClick={() => setSubtitle(true)}
			>
				<img src="/images/subtitle.svg" className="h-5" alt="Code" />
				<p className="font-bold text-black">UPLOAD SUBTITLES</p>
			</div>
			<div
				className="button-lg !bg-[#e83f6f] justify-center w-full p-4 flex gap-5 items-center"
				onClick={() => setDeleteVideo(true)}
			>
				<img
					src="/images/delete.svg"
					className="h-5 brightness-0 saturate-100"
					alt="Code"
				/>
				<p className="font-bold text-black">DELETE VIDEO</p>
			</div>
		</div>
	);
};

export default VideoPreview;
