import React, { useContext, useEffect, useState } from "react";
import Pagination from "../Pagination";
import { FileUploader } from "react-drag-drop-files";
import ContextVideoMenu from "./ContextVideoMenu";
import Progress from "../Progress";
import axios from "axios";
import { getUser } from "../../utils/getUser";
import { Dragging } from "../../context/DraggingContext";
import File from "../File";
import { checkFileClicked } from "../../utils/checkFileClicked";
import DeleteVideo from "../DeleteVideo";
import { Uploading } from "../../context/UploadingContext";
import { getAPIKey } from "../../utils/getAPIKey";

const VideosSection = ({
	result,
	uploader,
	isMenuVisible,
	setMenuVisible,
	setMoving,
	setFileDragged,
	plan,
	setUplaodDone,
	selectionMode,
	selections,
	setSelections,
}) => {
	const { dragging, setDragging } = useContext(Dragging);
	const [uploading, setUploading] = useState(false);
	const [videos, setVideos] = useState(result[1]);
	const [videosLoading, setVideosLoading] = useState(false);
	const {
		uploadProgress,
		setUploadProgress,
		filesUploading,
		setFilesUploading,
		currentFile,
		setCurrentFile,
	} = useContext(Uploading);
	const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
	const [fileActive, setFileActive] = useState(false);
	const [deleteVideo, setDeleteVideo] = useState(false);
	const [videoDeleted, setVideoDeleted] = useState({
		videoID: "",
		videoName: "",
	});
	const [error, setError] = useState(false);

	const updateVideo = (page) => {
		setVideosLoading(true);
		setError(false);
		const user = getUser();
		const apiKey = getAPIKey();
		const formData = new FormData();
		formData.append("user_id", user);
		formData.append("api_key", apiKey);
		formData.append("page", page);
		formData.append("limit", 8);
		axios
			.post("/list_videos.php", formData)
			.then((response) => {
				if (response.data.status === "success") {
					setVideos(response.data);
				} else {
					setError("An error occurred, Please try again!");
				}
			})
			.catch(() => {
				setError("An error occurred, Please try again!");
			})
			.finally(() => {
				setVideosLoading(false);
			});
	};

	useEffect(() => {
		const interval = setInterval(() => {
			const user = getUser();
			const apiKey = getAPIKey();
			const formData = new FormData();
			formData.append("user_id", user);
			formData.append("api_key", apiKey);
			formData.append("page", 1);
			formData.append("limit", 8);
			axios
				.post("/list_videos.php", formData)
				.then((response) => {
					if (response.data.status === "success") {
						setVideos(response.data);
					} else {
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => {});
		}, 300000);

		return () => clearInterval(interval);
	}, []);

	const uploadVideo = async (file) => {
		setUploading(true);

		const CHUNK_SIZE = 1 * 1024 * 1024;
		const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
		let currentChunk = 0;

		while (currentChunk < totalChunks) {
			const start = currentChunk * CHUNK_SIZE;
			const end = Math.min(start + CHUNK_SIZE, file.size);

			const chunk = file.slice(start, end);
			const apiKey = getAPIKey();
			const formData = new FormData();
			formData.append("file", chunk);
			formData.append("chunkIndex", currentChunk);
			formData.append("totalChunks", totalChunks);
			formData.append("fileName", file.name);
			formData.append("fileSize", file.size);
			const user = getUser();
			formData.append("user_id", user);
			formData.append("api_key", apiKey);

			try {
				await axios.post("/upload_video.php", formData);

				currentChunk++;
				const progress = (currentChunk / totalChunks) * 100;
				setUploadProgress(progress.toFixed(2));
			} catch (err) {
				console.error("Chunk upload failed:", err);
				alert("Upload failed. Please try again.");
				return;
			}
		}

		setUploading(false);
		setUploadProgress(0);
	};
	const [flipped, setFlipped] = useState(false);

	const videoContextMenu = (e) => {
		e.preventDefault();
		setMenuPosition({ x: e.pageX, y: e.pageY });
		if (e.pageX + 288 > document.body.clientWidth) {
			setFlipped(true);
		} else {
			setFlipped(false);
		}
		setMenuVisible();
		const id = checkFileClicked(e, "video-file", "video-container");
		if (id) {
			setSelections((selections) =>
				selections.includes(id) ? selections : [...selections, id]
			);
			if (!selections.length) {
				setFileActive(id);
			}
		} else {
			setFileActive(false);
		}
	};

	const resetSelection = (e) => {
		const id = checkFileClicked(e, "video-file", "video-container");
		if (!id) {
			setSelections([]);
		}
	};

	return (
		<>
			<div className="mt-10 mb-2 flex flex-col gap-5">
				<p className="font-bold text-xl">Videos</p>
				{uploading && (
					<>
						<p className="font-bold text-xl text-center">
							Uploading Now: {filesUploading[currentFile].name} |
							({currentFile + 1}/{filesUploading.length})
						</p>
						<div className="relative">
							<Progress
								height={20}
								width={uploadProgress}
								background="#7e3dd9"
							/>
							<p className="center-absolute text-xl">
								{uploadProgress}%
							</p>
						</div>
					</>
				)}
			</div>
			<div className="bg-gray" onClick={resetSelection}>
				{isMenuVisible && (
					<ContextVideoMenu
						setSelections={setSelections}
						menuPosition={menuPosition}
						selections={selections}
						setMoving={setMoving}
						fileActive={fileActive}
						setDeleteVideo={setDeleteVideo}
						setVideoDeleted={setVideoDeleted}
						flipped={flipped}
					/>
				)}
				<div className="relative p-5">
					{videosLoading && (
						<div className="absolute z-50 top-0 left-0 h-full w-full bg-white/50 flex justify-center items-center">
							<p className="text-black">Loading ....</p>
						</div>
					)}
					{!videos.videos?.length && (
						<p className="py-10 text-center font-bold  text-2xl">
							No Videos created yet.
						</p>
					)}
					{plan && (
						<FileUploader
							multiple={true}
							types={[
								"4mv",
								"amv",
								"avi",
								"flv",
								"m4p",
								"m4v",
								"mkv",
								"mov",
								"mp4",
								"mpeg",
								"mpg",
								"mxf",
								"ogg",
								"ts",
								"vod",
								"wav",
								"webm",
								"wmv",
							]}
							handleChange={async (dragfile) => {
								setUplaodDone(false);
								setDragging(false);
								setFilesUploading(dragfile);
								for (let i = 0; i < dragfile.length; i++) {
									setCurrentFile(i);
									await uploadVideo(dragfile[i]);
								}
								setFilesUploading([]);
								updateVideo(1);
								setUplaodDone(true);
							}}
							name="file"
							children={<div ref={uploader}></div>}
							classes={`${
								dragging ? "block" : "hidden"
							} !absolute top-0 left-0 h-full w-full z-50`}
						/>
					)}
					<div
						className="relative video-container grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-y-10"
						onContextMenu={videoContextMenu}
					>
						{videos.videos.map((f, i) => (
							<File
								key={i}
								selections={selections}
								setSelections={setSelections}
								{...f}
								setFileDragged={setFileDragged}
								className="video-file"
								selectionMode={selectionMode}
							/>
						))}
					</div>
					{videos.pagination.total_pages > 1 && (
						<Pagination
							pages={videos.pagination.total_pages}
							current={videos.pagination.current_page}
							update={updateVideo}
						/>
					)}
					{error && <p className="text-pink">{error}</p>}
				</div>
			</div>
			{deleteVideo && (
				<DeleteVideo
					setDeleteVideo={setDeleteVideo}
					setVideos={setVideos}
					{...videoDeleted}
				/>
			)}
		</>
	);
};

export default VideosSection;
