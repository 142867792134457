import React, { useState, createContext } from "react";

export const Uploading = createContext();

export const UploadingProvider = ({ children }) => {
	const [uploadProgress, setUploadProgress] = useState(0);
	const [filesUploading, setFilesUploading] = useState([]);
	const [currentFile, setCurrentFile] = useState(null);

	return (
		<Uploading.Provider
			value={{
				uploadProgress,
				setUploadProgress,
				filesUploading,
				setFilesUploading,
				currentFile,
				setCurrentFile,
			}}
		>
			{children}
		</Uploading.Provider>
	);
};
