import React, { useEffect, useRef } from "react";
import FolderVideos from "../components/Folder/FolderVideos";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";

const Folder = () => {
	const result = useLoaderData();
	const uploader = useRef(null);
	const folder = result[0];
	const navigate = useNavigate();
	useEffect(() => {
		if (!folder || folder.status === "error") {
			navigate("/404");
		}
	}, [folder]);
	const { id } = useParams();
	return (
		<div className="h-full bg-purpleSecondary [&_*]:">
			<div className="p-7 border-b-[1px] border-b-gray">
				<p className="font-bold text-3xl break-words">
					{folder.folder_name}
				</p>
			</div>
			<div className="p-7">
				<div className="mb-5 flex justify-end items-center gap-5">
					<div
						className="button-lg !bg-yellow flex justify-center items-center *:text-black gap-2 px-14 py-2"
						onClick={() => {
							uploader.current.click();
						}}
					>
						<img
							src="/images/upload-icon.svg"
							alt="Upload"
							className="h-5 brightness-0 saturate-100"
						/>
						<p>UPLOAD</p>
					</div>
				</div>
				<FolderVideos
					folderID={id}
					result={result}
					uploader={uploader}
				/>
			</div>
		</div>
	);
};

export default Folder;
