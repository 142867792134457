import React from "react";
import Wolvy from "./Wolvy";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<footer className="bg-gray py-10">
			<Wolvy />
			<div className="flex justify-center gap-5 items-center my-5">
				<Link to="https://www.facebook.com/people/Wolvy/61570933943971/">
					<img
						src="/images/facebook.svg"
						className="h-10"
						alt="Facebook"
					/>
				</Link>
				{/* <Link to="https://www.facebook.com/people/Wolvy/61570933943971/">
					<img
						src="/images/linkedin.svg"
						className="h-10"
						alt="Linkedin"
					/>
				</Link> */}
			</div>
			<div className="flex *:flex-1 justify-between">
				<div className="text-center">
					<p>&copy; 2024 Wolvy All Rights Reserved.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
